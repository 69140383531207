import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Footer from "../components/FooterMob";
import Header from "../components/HeaderMob";
import FnbContext from "../contextApi/FnbContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import {

  getUserCurrLoc,
  setUserSession,
  getUser,

} from "../utils/UserAuthenticate";
import { getSessionForModal, getSessionForMobile, setSessionForMobile } from "../utils/Helper";

import {
  apiBaseUrl,
  razorpayKey,

} from "../config/constant";
import { pickupTime, placedAnOrder, validateRestaurantTimings } from "../components/common";
import Slider from "react-slick";
import CheckMobileNumberOtp from "../components/checkMobileNumberOtp";

const settings = {
  dots: false,
  autoplay:true,
  infinite: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
  rtl: false,
  cssEase: "linear",
  arrows: false,
  // initialSlide: 1.5
};
const UserOrderSummary = () => {
  const navigate = useNavigate();
  const mobileVerified=getSessionForMobile('mobile');
  const userData=getUser()
  const {
    cartItems,
    subTotal,
    subTotalfunction,
    clearCart,
    deliveryCharge,
    resturantDetails
  } = useContext(FnbContext);
  if(resturantDetails === 'undefined' || cartItems.length === 0){
    navigate('/beer')
  }

  const initialValues = {
    username: "",
    email: "",
    phone: mobileVerified??"",
    address: "",
    deliveryType: "",
    cartItems,
    TotalAmount: 0,
    orderType: "1",
    userId : 'notlogin',
    payment_type:"",
    deliveryCharge,
    couponCode:"",
    restaurantName:resturantDetails?.restaurantName,
    restaurantPetpoojaId:resturantDetails?.restaurantPetpoojaId,
    restaurantPhone:resturantDetails?.restaurantPhone,
    restaurantEmail:resturantDetails?.restaurantEmail
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [failedPayments,setFaildPayments]=useState(false);
  const handleClosePopUpFailedPayments =()=>{ setFaildPayments(true) }
  const handleOpenPopUpFailedPayments =()=>{ setFaildPayments(true) }
  const [paymentDone,setPaymentDone]=useState(false)
  const [isFormValid, setIsFormValid] = useState(false);
  const [result ,setResult]=useState("error")
    const [isDisabled, setIsDisabled] = useState(true);
  const [couponError,setCouponError]= useState('')
  const [show, setShow] = useState(false);
  const [couponCodeTrue,setCouponCodeTrue]=useState('')
  const [discountAmount,setDiscountAmount]=useState('');
  const [deliveryLocation, setDeliveryLocation] = useState(()=>{return getSessionForModal('dileveryLocation')});

  const handleClose = () => {setShow(false);clearCart(); window.location = "/your-order-history";}
  // const handleShow = () => setShow(true);
  const [promoCodeList, setPromoCodeList]=useState([]);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  const getverifiedMobileNumber=()=>{
    const currentMobile = getSessionForMobile('mobile') ?? "";
    setFormValues((prevForm) => ({
        ...prevForm,
        phone: currentMobile,
      }));
  }

  const getUserDetails = async ()=>{
    let param = {
        userId : getUser()?.emailId
    }
    const response = await fetch(
      apiBaseUrl+"/fnb/getorderByUserID",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        
        body: JSON.stringify(param),
      }
    );
    const responseData = await response.json();

    if (responseData) {
      setFormValues({ 
        ...formValues, 
        username: responseData?.result?.customerName,
        phone: responseData?.result?.customerPhone,
        email: responseData?.result?.customerEmail,
        Appartment_no: responseData?.result.apartment_no,
        address: responseData?.result?.address,
        Land_mark: responseData?.result?.locality,
        // Pincode: responseData?.result?.pincode,
        Pincode: resturantDetails?.pincode,
        TotalAmount : subTotal
      });
    }
  }
  const getCouponList = async () =>{
    const response = await fetch(apiBaseUrl+"/common/get-promo-code");
    const responseData = await response.json();
  
    if (responseData?.response) {
      setPromoCodeList(responseData?.response?.result);
    }
  }
  
  const pincodeIni = () => {
    const pincode = sessionStorage.getItem('CURRENT_USER_PINCODE') || resturantDetails?.pincode;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      Pincode: pincode,
    }));
  };
  
  useEffect(() => {
    pincodeIni();
      if(resturantDetails?.timingDays){
        setIsDisabled(validateRestaurantTimings(resturantDetails))
      }
  }, [resturantDetails]);

  useEffect(() => {
    if(getUser()){ getUserDetails(); }
    if(userData){
      setSessionForMobile('mobile',userData.mobNum)
    }
    getCouponList();
  },[]);


    const handleApplyNow = async (code) => {
      setFormValues({ ...formValues, couponCode: code});
      if(formValues.email && formValues.phone){
        setIsCouponApplied(true);
        await getCouponData(code);
        setFormValues({ ...formValues, payment_type: "Online payment", couponCode: code});
      }else if(formValues.couponCode){ setCouponError("Please filled the above details first.")}
    }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  useEffect(() => {
    subTotalfunction();
  }, [subTotalfunction]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }
  const handleLocationChange = async (e)=>{
    const item =   JSON.parse(e.target.value);
    if(item){
      setFormValues({ ...formValues, 
        Land_mark : item.areaName,
        Pincode : item.pincode
       });
    }
  }
  // console.log(formValues,">")
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setCouponError('')
    switch (name) {
      case "username":
      case "phone":
      case "email":
      case "address":
      case "additional_info":
        setFormValues({ ...formValues, [name]: value });
        break;
      case "delivery_type":
        if (value === "102") {
          setFormValues({ ...formValues, delivery_type: "Home Delivery" });
        } else if (value === "104") {
          setFormValues({ ...formValues, delivery_type: "Office Delivery" });
        }
        break;
      case "costType":
        if(isCouponApplied){
          setFormValues({ ...formValues, payment_type: "Online payment" });
        }else{
          if (value === "1") {
            setFormValues({ ...formValues, payment_type: "Cash on delivery" });
          } else if (value === "2") {
            setFormValues({ ...formValues, payment_type: "Online payment" });
          }
        }
        break;
      case 'couponCode':
        if(name==="couponCode"){
          setCouponCodeTrue(false);
          handleRemoveCoupon();
          setIsCouponApplied(false);
          setFormValues({ ...formValues, couponCode: value });
        }
        break;
      case "Appartment_no":
        if (name === "Appartment_no") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
      case "Pincode":
        if (name === "Pincode") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
      case "Locality":
        if (name === "Locality") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
      case "Land_mark":
        if (name === "Land_mark") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
      default:
      break;
    }
    setIsFormValid(validate(formValues));
    // console.log('formValues',formValues);
  };

  const validate = (values) => {
    const errors = {};
    // const regex=
    if (!values.username) {
      errors.username = "Your name is required.";
    } else if (values.username.length < 4) {
      errors.username = "Your name must have a minimum of 4 characters.";
    } else if (!/^[a-zA-Z\s]+$/.test(values.username)) {
      errors.username = "Your name can only contain letters and spaces.";
    }
    
    if (!values.phone) {
      errors.phone = "Your phone no. is required.";
    } else if (!new RegExp(/^[0-9\b]+$/).test(values.phone)) {
      errors.phone = "Please enter only numbers.";
    } else if (values.phone.length > 10) {
      errors.phone = "Phone no. should not be greater than 10 digits.";
    }
    
    if (!values.email) {
      errors.email = " Your email address is required.";
    } else if (!new RegExp(/\S+@\S+\.\S+/).test(values.email)) {
      errors.email = "Incorrect email format!";
    }
    if (!values.address) {
      errors.address = "Please enter your address.";
    }


    return errors;
  };

// console.log(formErrors,"formErrorsCart")
  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    if (!isFormValid) {
      return; // don't submit the form if it's not valid
    }
    if (
      !formValues.username ||
      formValues.username.length < 4 ||
      !formValues.phone ||
      !new RegExp(/^[0-9\b]+$/).test(formValues.phone) ||
      formValues.phone.length > 10 || // Add this condition for phone length
      !formValues.email ||
      !new RegExp(/\S+@\S+\.\S+/).test(formValues.email) ||
      !formValues.address
    )  {
    
      return; // don't submit the form if any required fields are missing or have invalid values
    }  

    try {
      setIsSubmitButtonLoading(true);
      setTimeout(() => {
        setIsSubmitButtonLoading(false);
    }, 6000); 
      if (formValues.payment_type === "Online payment") {
        // getOrderId().then(async (orderData) => {
          const userCurrLoc = getUserCurrLoc();
          const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
          if (!res) {
            // Razorpay SDK failed to load. Handle the failure here.
            alert("Failed to proceed to order, Please try later!");
            setIsSubmitButtonLoading(false); // Set loading state to false in case of failure
            return;
          } else {
            let logo = "/homepage/images/splashLogo.png";
            const options = {
              key: razorpayKey,
              amount: formValues.TotalAmount*100,
              currency: 'INR',
              name: "Doolally",
              description: "Doolally FNB & Merchandise",
              handler: function (response) {
                if (response.razorpay_payment_id) {

                  const updatedFormValues = {
                    ...formValues, // Copy the existing formValues
                    transaction_id: response.razorpay_payment_id,
                    order_type: selectedOption === 'Pickup' ? 'P' : 'H', 
                  };
                  // Payment succeeded   
                  placedAnOrder(updatedFormValues).then((data)=>{      
                    if(data.statusCode === "success") 
                    {
                      
      // setIsSubmitButtonLoading(true);
                      setUserSession(
                        data.response.userToken,
                        data.response.userData[0]
                      );
                    }
                  setResult(
                    ` Wohoo ${
                      data?.response?.userData[0]?.userName
                    }! Your order has been placed. We've sent you a confirmation email too. We will begin prepping shortly. Cheers! `
                  );
                  setShow(true);
                  })
                  // console.log('Payment ID: ' + response.razorpay_payment_id);
                } else {
                  // Payment failed or was canceled
                  // console.log('Payment failed or canceled');
                  // console.log('Error description: ' + response.error.description);
                  handleOpenPopUpFailedPayments()
                }
              },
              ondismiss: function () {
                // Handle the case when the payment popup is closed without payment
                console.log('Payment popup dismissed without payment');
                setIsSubmitButtonLoading(false); // Set loading state to false
              },
              prefill: {
                name: formValues.username,
                email: formValues.email,
                contact: formValues.phone,
              },
              notes: {
                address: "Doolally Taproom",
              },
              theme: {
                color: "#6FAC2F",
              },
            };
    
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          }
        // });
      }else{
    const updatedFormValues = {
            ...formValues, // Copy the existing formValues
            order_type: selectedOption === 'Pickup' ? 'P' : 'H', 
          };
          placedAnOrder(updatedFormValues).then((data)=>{       
          if(data.statusCode === "success") 
          {
            setUserSession(
              data.response.userToken,
              data.response.userData[0]
            );
          }
        setResult(
          ` Wohoo ${
            data?.response?.userData[0]?.userName
          }! Your order has been placed. We've sent you a confirmation email too. We will begin prepping shortly. Cheers! `
        );
        setShow(true);
        })
      }
    }catch (error) {
      console.error(error);
    }
  };
    const getCouponData = async (code = "") => {
    try {
      const requestData = {
        promoCode: (code?code:formValues.couponCode).toUpperCase(),
        phone: formValues.phone,
        email: formValues.email
      };
      
      const headers = {
        'Content-Type': 'application/json'
      };
       await axios.post(apiBaseUrl+"common/validate-promo-code", requestData, { headers })
      .then(async response => {
     
        if(response?.data?.status){
          let e_amt = await response?.data?.response?.result[0]?.d_amount;
        if(response?.data?.response?.result.length === 0){
          setCouponError("coupn code is invalid");
          setIsCouponApplied(false);
        }else if(e_amt <= formValues.TotalAmount){
            setCouponCodeTrue(response?.data?.response?.result[0])
            setDiscountAmount(response?.data?.response?.result[0]?.d_amount);
            setIsCouponApplied(true);
            setCouponError('')
          }else{
            alert(`Total amount should be greater than ${response?.data?.response?.result[0]?.e_amount}`)
            setIsCouponApplied(false);
            // couponCodeTrue("");
          }
        }else{
          setCouponError("coupn code is invalid");
          setIsCouponApplied(false);
        }
      })
      .catch(error => {
        console.error('Error:', error);
        setIsCouponApplied(false);
      });
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
      setIsCouponApplied(false);
    }
  };

  const handleCoupon=async ()=>{
      if(formValues.email && formValues.phone){
        setIsCouponApplied(true);
        await getCouponData();
        setFormValues({ ...formValues, payment_type: "Online payment"});
      }else if(formValues.couponCode){ setCouponError("Please filled the above details first.")}
      else if(!formValues.couponCode){setCouponError("Coupon code is required.")}
  }
  const handleRemoveCoupon=async ()=>{
    setFormValues({ ...formValues, couponCode: ""});
    setDiscountAmount(0);
    setIsCouponApplied(false);
    setCouponCodeTrue(false);
}

      useEffect(() => {
        setFormValues((prevValues) => ({
          ...prevValues,
          TotalAmount: subTotal-discountAmount,
        }));
        subTotalfunction();
        
      }, [subTotal,discountAmount,subTotalfunction]);

  const [selectedOption, setSelectedOption] = useState("Delivery"); // Pickup or Delivery
  const [pickupSlot, setPickupSlot] = useState(pickupTime()); // Selected pickup time slot
  const [errors, setErrors] = useState({}); // Error handling state
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setErrors({ ...errors, option: "" });
    if (option === "Delivery") {
      setPickupSlot(""); // Reset pickup slot if Delivery is selected
    }
  };

  return (
    <Container>
      <Header />

      {paymentDone===true?   "":
      <div className="cart-form box_padding mb-4 pb-4 mt-4 pt-4">
        <label for="name" >Name</label>
        <input
          type="text"
          id="name"
          name="username"
          placeholder="Your Name"
          onChange={(e) => {
            const { value } = e.target;
            // Allow only alphabetic characters (A-Z, a-z) and spaces
            const regex = /^[a-zA-Z\s]*$/;
            if (regex.test(value)) {
              handleChange(e); // Call handleChange only if the value is valid
            }
          }}
          value={formValues.username?formValues.username:""}
        />
        <p style={{ color: "red", marginLeft: "4%" }}>{formErrors.username}</p>
        <label for="email" className="mt-2">Email</label>
        <input
          type="email"
          name="email"
          placeholder="Email id"
          onChange={handleChange}
          value={formValues.email?formValues.email:""}
        />
        <p style={{ color: "red", marginLeft: "4%" }}>{formErrors.email}</p>
        <label for="phone" className="mt-2">Phone</label>
        <input
          type="tel"
          placeholder="Phone no"
          name="phone"
          onChange={handleChange}
          value={formValues.phone?formValues.phone:""}
          readOnly
        />
            <p style={{ color: "red", marginLeft: "4%" }}>{formErrors.phone}</p>
            {selectedOption === "Delivery" && (
<>
            <label for="name" className="mt-2">Apartment no</label>
        <input
          type="text"
          id="name"
          name="Appartment_no"
          placeholder="Apartment No."
          onChange={handleChange}
          value={formValues.Appartment_no?formValues.Appartment_no:""}
          
        />
        <label for="address" className="mt-2">Address</label>
        <textarea
          id="address"
          name="address"
          rows="4"
          onChange={handleChange}
          value={formValues.address?formValues.address:""}
        />

        <label for="name" className="mt-2">Locality</label>

        {/* <input
          type="text"
          id="name"
          name="Locality"
          placeholder="Locality"
          onChange={handleChange}
          value={formValues.Land_mark}
        /> */}

          {/* {deliveryLocation ? (
            <>
            <select className="col delivryAddress" name="Land_mark" onChange={handleLocationChange} >
              <option value={JSON.stringify({})}>Select Location</option>
              {deliveryLocation.map((item, id) => (

                <option value={JSON.stringify(item)}>{item.areaName}</option>
              ))}
            </select></>
            ) : (<> */}
            <input
            type="text"
            className="col"
            placeholder="Locality"
            name="Land_mark"
            onChange={handleChange}
            value={formValues.Land_mark?formValues.Land_mark:""}
          />
            {/* </>)
          } */}

        <label for="name" className="mt-2">Pincode</label>
        <input
          type="text"
          id="name"
          name="Pincode"
          placeholder="Pincode"
          onChange={handleChange}
          value={formValues.Pincode?formValues.Pincode:""}
          // disabled={resturantDetails?.pincode?true:false}
          // disabled={formValues.Land_mark ? true : formValues?.Pincode?true: false}
        />
  
        <p style={{ color: "red", marginLeft: "6%" }}>{formErrors.address}</p>
        
        </>
            )}
        <div className="col-12 mt-2">
        <p className="d_main_title " style={{lineHeight:"35px"}}> Offers / Coupon code </p>
        <span class="coupon-code">
        <input 
          type="text" 
          placeholder="Enter your coupon code" 
          name="couponCode" 
          onChange={handleChange} 
          value={formValues?.couponCode}/> 
          {isCouponApplied?<button onClick={handleRemoveCoupon}>Remove</button>:<button onClick={handleCoupon}>Redeem</button>}
          
        </span>
        {couponError&& <p className="error_coupon2">{couponError}</p>}
        </div>
        <br/>

        {isCouponApplied&& <p className="valid_coupon">Woohoo! You just got Rs. {couponCodeTrue.d_amount}/- discount.</p>}
        {/* Promo Code Display Block */}
        {(promoCodeList && (
          <Row>
          <Col>
            <div>
              <div className=" box_paddin gray_bg_color"  style={{padding:"0% 5% 0% 5%"}} >
                <div className="d_title_box">
                  <div className="d_title_box">
                    <p className="d_main_sub_title pt-2 mb-4">
                      Latest Promo Code<span className="d_line"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                    </p>
                    <Slider {...settings}>
                    {promoCodeList.map((item, index)=>( 
                        <>
                        <div className=" mt-2 d-flex justify-content-center">
                          <p style={{letterSpacing:"2px", fontWeight:"900"}}>"{item.code}" {" "}</p>
                         {" "} <p>    {" "} Order {item.e_amount} and above.</p>
                        </div>
                        <div className="d-flex justify-content-center">
                          {/* <p>Discount</p> */}
                          <p>{item.description} </p>
                        </div>

                          <div class=" col-3 d_comn_btn d-block mb-2 mt-2" id="cart_btn2" style={{ margin: "5px auto", cursor: "pointer",fontSize: "12px" }} onClick={()=>handleApplyNow(item.code)} > Apply Now</div>

                        {/* <hr/> */}
                        </>
                      ))}
        </Slider>
                    {/* <div className="scroll-parent" style={{height: "95px"}}> */}
                      {/* {promoCodeList.map((item, index)=>( 
                        <>
                        <div className="d-flex justify-content-between">
                          <p>{item.code}</p>
                          <p>Order {item.e_amount} and above.</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p>{item.description} </p>
                        </div>
                          <div class=" col-3 d_comn_btn d-block" id="cart_btn2" style={{ margin: "5px auto", cursor: "pointer",fontSize: "12px" }} onClick={()=>handleApplyNow(item.code)} > Apply Now</div>
                        <hr/>
                        </>
                      ))} */}
                    {/* </div> */}

                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
        ))}
        
        <div className="container mb-3 p-4 bg-white  rounded pickupOption" style={{border:"1px solid #a2c760"}}>
  {/* Service Option Title */}
  <h4 className="mb-3 fw-bold text-secondary">Choose Service Option</h4>

  {/* Service Option Buttons */}
  <div className="d-flex justify-content-start ">
    <button
      className={`btn ${selectedOption === "Pickup" ? "btn-primary-custom active-option" : "btn-outline-primary"} me-2`}
      onClick={() => handleOptionChange("Pickup")}
      disabled={isDisabled}
    >
      Pickup
    </button>
    <button
      className={`btn ${selectedOption === "Delivery" ? "btn-primary-custom active-option" : "btn-outline-primary"}`}
      onClick={() => handleOptionChange("Delivery")}
    >
      Delivery
    </button>
  </div>
  {isDisabled && (
  resturantDetails?.timingDays ? (
    <p style={{ color: "red" }}>
      Restaurant opening time is from {resturantDetails?.timingFrom} to {resturantDetails?.timingTo} for pickup.
    </p>
  ) : (
    <p style={{ color: "red" }}>
      No operating time available for the restaurant pickup.
    </p>
  )
)}
  {/* Confirmation Section */}
  {selectedOption === "Pickup" && (
    <div className="mt-3 pickup shadow-sm">
      <h5 className="fw-bold mb-3">Order Confirmation</h5>
      <p className="mb-2">
        <strong>Pickup Location:</strong> <br />
        {resturantDetails?.restaurantAddress}
      </p>
      <p className="mb-2">
        <strong>Selected Pickup Time:</strong> {pickupSlot}
      </p>
      <p>
        <strong>Estimated Time for Preparation:</strong> 15-20 mins
      </p>
    </div>
  )}
</div>

        <div className="col-12 ">
          <p className="d_main_title "> Payment</p>

          <div className="col-12 mt-3">
            <label className="d_custome_raido " id="pay-mode-mob">
              <span className="d_paid monster">Cash on delivery</span>
              <input
                type="radio"
                name="costType"
                id="costType1"
                value="1"
                // {...(isCouponApplied?{checked:false}:"")}
                onChange={handleChange}
                
              />
              <span className="checkmark"></span>
            </label>
          </div>
          <div className="col-12">
            <label className="d_custome_raido" id="pay-mode-mob">
              <span className="d_paid monster">
                Credit Card/Debit Card/Netbanking 
              </span>
              <input
                type="radio"
                name="costType"
                id="costType2"
                value="2"
                onChange={handleChange}
              />
              <span className="checkmark"></span>
            </label>
          </div>
        </div>
        <Row>
          <Col>
            <div>
              <div className=" box_paddin gray_bg_color"  style={{padding:"0% 5% 0% 5%"}} >
                <div className="d_title_box">
                  <div className="d_title_box">
                    <p className="d_main_sub_title pt-2 pb-4 mb-4">
                      Bill Details<span className="d_line"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                      <span className="d_round"></span>
                    </p>

                    <div className="d-flex justify-content-between">
                      <p>Amount</p>
                  
                      <p>₹     {(subTotal- (deliveryCharge ?? cartItems[0]?.deliveryCharge))}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Discount</p>
                      <p>₹ {discountAmount || "0"} </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p>Delivery charges</p>
                      <p>₹ {(deliveryCharge ?? cartItems[0]?.deliveryCharge)}</p>
                    </div>
                    <div className="d-flex justify-content-between pb-2">
                      <p>Total Amount</p>
                      <p>₹ {formValues.TotalAmount}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <button type="submit" className="mb-4  mt-4" onClick={handleSubmit}>
          {isSubmitButtonLoading ? "Loading..." : "   Place Order"}
        </button>
      </div>
    }
      <Footer />
      { !mobileVerified?<CheckMobileNumberOtp verifiedNumber={getverifiedMobileNumber}/>:""}
      <Modal show={show} onHide={handleClose } centered>
        <Modal.Header closeButton>
          <Modal.Title>Your Order is Confirmed</Modal.Title>
        </Modal.Header>
        <Modal.Body>{result}</Modal.Body>
        <Modal.Footer>
          <button variant="secondary"  className="add-on-more-btn" onClick={() => {
  handleClose();
  localStorage.clear()
  window.location = '/your-order-history';
}}
            >
            Close
          </button>
          
        </Modal.Footer>
      </Modal>
      <Modal show={failedPayments} onHide={handleClosePopUpFailedPayments} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Failed !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sorry, your payment has failed, Please try again later.</Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            className="add-on-more-btn"
            onClick={() => {
              handleClose();
              window.location = "/";
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </Container>


  );
};

export default UserOrderSummary;
