import React, { useEffect, useState, useContext } from "react";
import Header from "../components/HeaderWeb";
import Footer from "../components/FooterWeb";
import Modal from "react-bootstrap/Modal";
import FnbContext from "../contextApi/FnbContext";
import { useNavigate } from "react-router-dom";
import { setSessionForModal,getSessionForModal, getSessionForAmount, setSessionForMobile, getSessionForMobile } from "../utils/Helper";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import axios from "axios";
import moment from 'moment';


import {
  getUserCurrLoc,
  setUserSession,
  getUser,
} from "../utils/UserAuthenticate";

import {
  userFrom,
  postRequestOptions,
  razorpayKey,
  apiBaseUrl,
} from "../config/constant";
import { getOrderId, pickupTime, placedAnOrder, validateRestaurantTimings } from "../components/common";
import Slider from "react-slick";
import { Container } from "react-bootstrap";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import CheckMobileNumberOtp from "../components/checkMobileNumberOtp";


const settings = {
  dots: false,
  autoplay: false,
  infinite: true,
  speed: 200,
  slidesToShow: 1.4,
  slidesToScroll: 1,
  rtl: false,
  cssEase: "linear",
  arrows: false,
  // initialSlide: 1.5
};

const Cart = () => {
  const navigate = useNavigate();

  const {
    cartItems,
    handleChangeIncDec,
    subTotal,
    subTotalfunction,
    clearCart,
    deliveryCharge,
    resturantDetails
  } = useContext(FnbContext);

  const mobileVerified=getSessionForMobile('mobile')
  sessionStorage.setItem('restaurantDetails', JSON.stringify(resturantDetails));
  if(resturantDetails === 'undefined' || cartItems.length === 0){
    navigate('/beer')
  }

  const userData = getUser();
  const initialValues = {
    username: "",
    email: "",
    phone: mobileVerified??'',
    address: "",
    deliveryType: "",
    cartItems,
    // TotalAmount: 0 ,
    TotalAmount: cartItems.reduce((total, item) => total + (item.price), 0)+cartItems[0]?.deliveryCharge, // Calculate total
    orderType: "1",
    userId: "notlogin",
    payment_type: "",
    // deliveryCharge: deliveryCharge,
    deliveryCharge: cartItems[0]?.deliveryCharge || 0, 
    couponCode: "",
    restaurantName:resturantDetails?.restaurantName,
    restaurantPetpoojaId:resturantDetails?.restaurantPetpoojaId,
    restaurantPhone:resturantDetails?.restaurantPhone,
    restaurantEmail:resturantDetails?.restaurantEmail
  };
  const [formValues, setFormValues] = useState(initialValues);
  

  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [result, setResult] = useState("error");
  const [show, setShow] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
    const [offersZone, setOfferZone] = useState(false);
  const [failedPayments,setFaildPayments]=useState(false);
  const handleOpenPopUpFailedPayments =()=>{ setFaildPayments(true) }
  const handleClosePopUpFailedPayments =()=>{ setFaildPayments(true) }
  const handleCloseOfferZone=()=>{setOfferZone(false)}
  const [couponError, setCouponError] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [paymentDone, setPaymentDone] = useState(false);
  const handleClose = () => {setShow(false);clearCart(); window.location = "/your-order-history";}
  const handleShow = () => setShow(true);
  const [showPickupTimeError, setShowPickupTimeError] = useState(false);
const [selectedOption, setSelectedOption] = useState("Delivery"); // Pickup or Delivery
  const [couponCodeTrue, setCouponCodeTrue] = useState("");

  const [deliveryLocation, setDeliveryLocation] = useState(()=>{return getSessionForModal('dileveryLocation')});
  const [promoCodeList, setPromoCodeList]=useState([]);
  const [isCouponApplied, setIsCouponApplied] = useState(false);

  const getverifiedMobileNumber=()=>{
    const currentMobile = getSessionForMobile('mobile') ?? "";
    setFormValues((prevForm) => ({
        ...prevForm,
        phone: currentMobile,
      }));
  }
  const getUserDetails = async ()=>{
    let param = {
        userId : getUser()?.emailId

    }
    const response = await fetch(
      apiBaseUrl+"/fnb/getorderByUserID",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        
        body: JSON.stringify(param),
      }
    );
    const responseData = await response.json();

    if (responseData) {
      setFormValues({ 
        ...formValues, 
        username: responseData?.result?.customerName,
        phone: responseData?.result?.customerPhone,
        email: responseData?.result?.customerEmail,
        Appartment_no: responseData?.result.apartment_no,
        address: responseData?.result?.address,
        Land_mark: responseData?.result?.locality,
        // Pincode: responseData?.result?.pincode,
        Pincode: resturantDetails?.pincode,
        // TotalAmount : subTotal
      });
    }
  }

const getCouponList = async () =>{
   
  const response = await fetch(apiBaseUrl+"/common/get-promo-code");
  const responseData = await response.json();

  if (responseData?.response) {
    setPromoCodeList(responseData?.response?.result);
  }
}

const pincodeIni = () => {
  const pincode = sessionStorage.getItem('CURRENT_USER_PINCODE');
  setFormValues((prevFormValues) => ({
    ...prevFormValues,
    Pincode: pincode || resturantDetails?.pincode,
  }));
};

useEffect(() => {
  pincodeIni();
  if(resturantDetails?.timingDays){
    setIsDisabled(validateRestaurantTimings(resturantDetails))
  }
}, [resturantDetails]);

  
  useEffect(() => {
    if(getUser()){
      getUserDetails();
    }
    if(userData){
      setSessionForMobile('mobile',userData.mobNum)
    }
    getCouponList();

  },[]);
  useEffect(() => {
    if (resturantDetails) {
      setFormValues((prevValues) => ({
        ...prevValues,
        restaurantEmail: resturantDetails.restaurantEmail,
        restaurantName: resturantDetails.restaurantName,
        restaurantPetpoojaId: resturantDetails.restaurantPetpoojaId,
        restaurantPhone: resturantDetails.restaurantPhone,
      }));
    }
  }, [resturantDetails]);
  
  useEffect(() => {
    if(cartItems && cartItems.length === 0){
      navigate('/beer');
    }
    
    subTotalfunction();
    let updatedTotalAmount = cartItems.reduce((total, item) => total + item.price, 0);
    let updatedDeliveryCharge = 0; // Default to 0 for Pickup
  
    if (selectedOption === "Delivery") {
      // Add delivery charge and subtract discount if Delivery is selected
      updatedDeliveryCharge = cartItems[0]?.deliveryCharge ?? 0; // Get the delivery charge
      updatedTotalAmount += updatedDeliveryCharge - (discountAmount || 0); // Add delivery charge and apply discount

    } else if (selectedOption === "Pickup") {
      // Subtract discount if Pickup is selected
      updatedTotalAmount -= discountAmount || 0; // Apply discount
     
    }
    setFormValues((prevValues) => ({
      ...prevValues,
      TotalAmount: Math.max(updatedTotalAmount, 0), // Ensure total does not go below zero
      deliveryType: selectedOption,
      deliveryCharge: updatedDeliveryCharge, // Update delivery charge based on the option
    }));
  }, [subTotal, discountAmount]);
 
  // console.log('promoCodeList',promoCodeList);
  const handleLocationChange = async (e)=>{
    const item =   JSON.parse(e.target.value);
    if(item){
      setFormValues({ ...formValues, 
        Land_mark : item.areaName,
        Pincode : item.pincode
       });
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCouponError("");
    switch (name) {
      case "username":
        setFormValues({ ...formValues, [name]: value });
        setFormErrors((preError)=>({
          ...preError,
          username : ""
        }))
        break;
      case "phone":
        setFormValues({ ...formValues, [name]: value });
        setFormErrors((preError)=>({
          ...preError,
          phone : ""
        }))
        break;
      case "email":
        setFormValues({ ...formValues, [name]: value });
        setFormErrors((preError)=>({
          ...preError,
          email : ""
        }))
        break;
      case "address":
        setFormValues({ ...formValues, [name]: value });
        setFormErrors((preError)=>({
          ...preError,
          address : ""
        }))
        break;
      case "additional_info":
        setFormValues({ ...formValues, [name]: value });
        break;
      case "delivery_type":
        if (value === "102") {
          setFormValues({ ...formValues, delivery_type: "Home Delivery" });
        } else if (value === "104") {
          setFormValues({ ...formValues, delivery_type: "Office Delivery" });
        }
        break;
      case "costType":
        if(isCouponApplied){
          setFormValues({ ...formValues, payment_type: "ONLINE" });
        }else{
          if (value === "1") {
            setFormValues({ ...formValues, payment_type: "COD" });
          } else if (value === "2") {
            setFormValues({ ...formValues, payment_type: "ONLINE" });
          }
        }
        break;
      case "couponCode":
        if (name === "couponCode") {
          setCouponCodeTrue(false);
          handleRemoveCoupon();
          setIsCouponApplied(false)
          setFormValues({ ...formValues, couponCode: value });
        }
        break;
      case "Appartment_no":
        if (name === "Appartment_no") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
      case "Pincode":
        if (name === "Pincode") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
      case "Land_mark":
        if (name === "Land_mark") {
          setFormValues({ ...formValues, [name]: value });
        }
        break;
     
      default:
        break;
    }
    // setIsFormValid(validate(formValues));
    // console.log('formValues',formValues);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
     if (selectedOption === "Pickup" && !pickup_time) {
      setShowPickupTimeError(true); // Show the error message in the table
      return; // Stop form submission
    } else {
      setShowPickupTimeError(false); // Hide the error message if valid
    }
    if (!validate(formValues)) {
      return; // don't submit the form if it's not valid
    }else{
      if (
        !formValues.username ||
        formValues.username.length < 4 ||
        !formValues.phone ||
        !new RegExp(/^[0-9\b]+$/).test(formValues.phone) ||
        !formValues.email ||
        !new RegExp(/\S+@\S+\.\S+/).test(formValues.email) 
        // ||
       // !formValues.address
      ) {
        return; // don't submit the form if any required fields are missing or have invalid values
      }
      if (couponError) {
        alert("cannot proceed with invalid coupon code");
        return;
      }
   
      try {
        setIsSubmitButtonLoading(true);
        setTimeout(() => {
          setIsSubmitButtonLoading(false);
      }, 6000); 
        if (formValues.payment_type === "ONLINE") {
          // getOrderId().then(async (orderData) => {
            const userCurrLoc = getUserCurrLoc();
            const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
            if (!res) {
              // Razorpay SDK failed to load. Handle the failure here.
              alert("Failed to proceed to order, Please try later!");
              setIsSubmitButtonLoading(false); // Set loading state to false in case of failure
              return;
            } else {
              let logo = "/homepage/images/splashLogo.png";
              const options = {
                key: razorpayKey,
                amount: formValues.TotalAmount*100,
                currency: 'INR',
                name: "Doolally",
                description: "Doolally FNB & Merchandise",
                handler: function (response) {
                  if (response.razorpay_payment_id) {
                    const updatedFormValues = {
                      ...formValues, // Copy the existing formValues
                      transaction_id: response.razorpay_payment_id,
                      preorder_time: selectedOption === "Pickup" ? pickup_time : null,
                      preorder_date: selectedOption === "Pickup" ? moment().format('YYYY-MM-DD') : null,
                      order_type: selectedOption === 'Pickup' ? 'P' : 'H', 
                    };
                    placedAnOrder(updatedFormValues).then((data)=>{      
                      if(data.statusCode === "success") 
                      {
                          setUserSession(
                          data.response.userToken,
                          data.response.userData[0]
                        );
                      }
                    setResult(
                      ` Wohoo ${
                        data?.response?.userData[0]?.userName
                      }! Your order has been placed. We've sent you a confirmation email too. We will begin prepping shortly. Cheers! `
                    );
                    setShow(true);
                    })
                  } else {
                    handleOpenPopUpFailedPayments()
                  }
                },
                ondismiss: function () {
                  // Handle the case when the payment popup is closed without payment
                  setIsSubmitButtonLoading(false); // Set loading state to false
                },
                prefill: {
                  name: formValues.username,
                  email: formValues.email,
                  contact: formValues.phone,
                },
                notes: {
                  address: "Doolally Taproom",
                },
                theme: {
                  color: "#6FAC2F",
                },
              };
      
              const paymentObject = new window.Razorpay(options);
              paymentObject.open();
    
            }
          // });
        }else{
          const updatedFormValues = {
            ...formValues, // Copy the existing formValues
            // pickup_time: pickup_time,
            preorder_time: selectedOption === "Pickup" ? pickup_time : null,
            preorder_date: selectedOption === "Pickup" ? moment().format('YYYY-MM-DD') : null,
            order_type: selectedOption === 'Pickup' ? 'P' : 'H', 
          };
          placedAnOrder(updatedFormValues).then((data)=>{      
            if(data.statusCode === "success") 
            {
              setUserSession(
                data.response.userToken,
                data.response.userData[0]
              );
            }
            setResult(
              ` Wohoo ${
                data?.response?.userData[0]?.userName
              }! Your order has been placed. We've sent you a confirmation email too. We will begin prepping shortly. Cheers! `
            );
            setShow(true);
          })
          
        }
      } catch (error) {
        console.error(error);
        setIsSubmitButtonLoading(false); // Set loading state to false in case of an error
      }
    }
  }
    

  const validate = (values) => {
    const errors = {};
    if (!values.username) {
      errors.username = "Your name is required.";
      setFormErrors((preError)=>({
        ...preError,
        username : "Your name is required."
      }));
      return false;
    } else if (values?.username && values.username.length < 4) {
      errors.username = "Your name must have a minimum of 4 characters.";
      setFormErrors((preError)=>({
        ...preError,
        username : "Your name must have a minimum of 4 characters."
      }));
      return false;
    } else if(!values.phone) {
      errors.phone = "Your phone number is required.";
      setFormErrors((preError)=>({
        ...preError,
        phone : "Your phone number is required."
      }));
      return false;
    // } else if (formValues?.phone && !/^[0-9]{10}$/.test(formValues.phone)) {
    } else if (formValues?.phone && (formValues.phone).length !== 10) {
      errors.phone = "Please enter a 10-digit phone number.";
      setFormErrors((preError)=>({
        ...preError,
        phone : "Please enter a 10-digit phone number"
      }));
      return false;
    } else if (!values.email) {
      errors.email = "Your email address is required.";
      setFormErrors((preError)=>({
        ...preError,
        email : "Your email address is required."
      }));
      return false;
    } else if (values?.email && !/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Incorrect email format!";
      setFormErrors((preError)=>({
        ...preError,
        email : "Incorrect email format!"
      }));
      return false;
    } 
   
    else if(!formValues.payment_type){
      setFormErrors((preError)=>({
        ...preError,
        payment_type : "Please select payment."
      }));
      return false;
    } else if(selectedOption === "Delivery" && !values.address)
      {
        errors.address = "Please enter your address.";
        setFormErrors((preError)=>({
          ...preError,
          address : "Please enter your address."
        }));
        return false;
    } else{
      return true  
    }
  };

  const getCouponData = async (code="") => {
    try {
      const requestData = {
        promoCode: (code?code:formValues.couponCode).toUpperCase(),
        phone: formValues.phone,
        email: formValues.email,
      };

      const headers = {
        "Content-Type": "application/json",
      };
      const response = await axios
        .post(apiBaseUrl + "common/validate-promo-code", requestData, {
          headers,
        })
        .then(async (response) => {
          if(response?.data?.status){
            let e_amt = await response?.data?.response?.result[0]?.d_amount;
            if(response?.data?.response?.result.length === 0){
              setCouponError("coupn code is invalid");
              setIsCouponApplied(false);
            }else if(e_amt <= formValues.TotalAmount){
                setCouponCodeTrue(response?.data?.response?.result[0])
                setDiscountAmount(response?.data?.response?.result[0]?.d_amount);
                setIsCouponApplied(true);
                setCouponError('');
            }else{
              alert(`Total amount should be greater than ${response?.data?.response?.result[0]?.e_amount}`)
              setIsCouponApplied(false);
            }
          }else{
            setCouponError("coupn code is invalid");
            setIsCouponApplied(false);
          }
        })
        .catch((error) => {
          if (error.response.data.responseCode) {
            setCouponError(error.response.data.statusMessage);
          }
        });
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  const handleCoupon=async ()=>{
    if(formValues.email && formValues.phone){
      setIsCouponApplied(true);
      await getCouponData();
      setFormValues({ ...formValues, payment_type: "ONLINE"});
    }else if(formValues.couponCode){ setCouponError("Please filled the above details first.")}
    else if(!formValues.couponCode){setCouponError("Coupon code is required.")}
}
const handleRemoveCoupon=async ()=>{
  setFormValues({ ...formValues, couponCode: ""});
  setDiscountAmount(0);
  setIsCouponApplied(false);
  setCouponCodeTrue(false);
}

  const handleApplyNow = async (code) => {
    if(!formValues.phone || !formValues.email || !formValues.username){ setCouponError("Please filled the above details first."); return}
    setFormValues({ ...formValues, couponCode: code });
    if(formValues.email && formValues.phone){
      setIsCouponApplied(true);
      await getCouponData(code);
      setFormValues({ ...formValues, payment_type: "ONLINE", couponCode: code});
    }else if(formValues.couponCode){ setCouponError("Please filled the above details first.")}
  }

  
  const [pickupSlot, setPickupSlot] = useState(pickupTime()); // Selected pickup time slot
  // const [pickup_time, setPickupTime] = useState(pickupSlot.length > 0 ? pickupSlot[0].startTime : null);
  const [pickup_time, setPickupTime] = useState(null);
  const [errors, setErrors] = useState({}); // Error handling state
  const handleOptionChange = (option) => {
    setSelectedOption(option);
    setErrors({ ...errors, option: "" });
  
    // Calculate the base total amount from cart items
    let updatedTotalAmount = cartItems.reduce((total, item) => total + item.price, 0);
    let updatedDeliveryCharge = 0; // Default to 0 for Pickup
  
    if (option === "Delivery") {
      // Add delivery charge and subtract discount if Delivery is selected
      updatedDeliveryCharge = cartItems[0]?.deliveryCharge ?? 0; // Get the delivery charge
      updatedTotalAmount += updatedDeliveryCharge - (discountAmount || 0); // Add delivery charge and apply discount
      setPickupSlot([]); // Reset pickup slots
      setPickupTime(null); // Clear pickup time
    } else if (option === "Pickup") {
      // Subtract discount if Pickup is selected
      updatedTotalAmount -= discountAmount || 0; // Apply discount
      const newPickupSlots = pickupTime(); // Regenerate pickup slots
      setPickupSlot(newPickupSlots); // Update pickup slots
      checkCurrentTimeSlot(newPickupSlots); // Set the correct pickup time
    }
  
    // Update the TotalAmount, deliveryType, and deliveryCharge in formValues
    setFormValues((prevValues) => ({
      ...prevValues,
      TotalAmount: Math.max(updatedTotalAmount, 0), // Ensure total does not go below zero
      deliveryType: option,
      deliveryCharge: updatedDeliveryCharge, // Update delivery charge based on the option
    }));
  };
  
  
  
  const checkCurrentTimeSlot = (slots) => {
    if (!Array.isArray(slots) || slots.length === 0) {
      setPickupTime(null);
      return;
    }
  
    const now = new Date(); // Get the current time
  
    // Find the first valid slot based on the current time
    const validSlot = slots.find((slot) => {
      const [startHours, startMinutes] = slot.startTime.split(":").map(Number);
      const [endHours, endMinutes] = slot.endTime.split(":").map(Number);
  
      const slotStart = new Date();
      const slotEnd = new Date();
  
      slotStart.setHours(startHours, startMinutes, 0, 0);
      slotEnd.setHours(endHours, endMinutes, 0, 0);
  
      return now >= slotStart && now <= slotEnd;
    });
  
    if (validSlot) {
      // If the current time is within a valid slot range
      setPickupTime(now.toTimeString().split(" ")[0]); // Set to the current time
    } else {
      // If no valid slot is found, default to the first slot's start time
      setPickupTime(slots[0].startTime);
    }
  };
  
  // Run `checkCurrentTimeSlot` whenever `pickupSlot` changes
  useEffect(() => {
    checkCurrentTimeSlot(pickupSlot);
    
  }, [pickupSlot]);
  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="cartpgnme">
        {paymentDone == true ? (
          ""
        ) : (
          <div className="row">
            <div className="col-7" style={{paddingRight:"3%"}}>
              <div className=" mt-4  shadow-zoom-effect">
                <div className=" bg-white box_padding ">
                  <p className="d_main_title poppins bglight" id="titlemr">
          
                    User Information
                  </p>
                  <div className="cart-log-sign ">
                    <div id="sinup_cart">
                      <div className="row gy-1 " id="cart_address" style={{ margin: "0px auto" }}>
                        {/* Name */}
                        <span style={{ width: "33%" }}>
                          <input
                            className="col-4"
                            type="text"
                            placeholder="Name"
                            name="username"
                            onChange={(e) => {
                              const { value } = e.target;
                              // Allow only alphabetic characters (A-Z, a-z) and spaces
                              const regex = /^[a-zA-Z\s]*$/;
                              if (regex.test(value)) {
                                handleChange(e); // Call handleChange only if the value is valid
                              }
                            }}
                            // onChange={handleChange}
                            value={formValues.username?formValues.username:""}
                          />{" "}
                          <br />
                          <p style={{ color: "red", marginLeft: "4%" }}>
                            {formErrors.username}
                          </p>
                        </span>

                        {/* Phone no. */}
                        <span style={{ width: "33%" }}>
                          <input
                            className="col-4"
                            type="text"
                            placeholder="Phone no"
                            name="phone"
                            onChange={handleChange}
                            value={formValues.phone?formValues.phone:""}
                            readOnly
                          />
                          <p style={{ color: "red", marginLeft: "4%" }}>
                            {formErrors.phone}
                          </p>
                        </span>

                        <span style={{ width: "33%" }}>
                          <input
                            className="col-4"
                            type="text"
                            name="email"
                            placeholder="Email id"
                            onChange={handleChange}
                            value={formValues.email?formValues.email:""}
                          />
                          <p style={{ color: "red", marginLeft: "4%" }}>
                            {formErrors.email}
                          </p>
                        </span>
                      
                        {/* <label className="col" style={{ marginLeft: "3px" }}>
                        Password
                        <input type="text" />
                      </label> */}
                      </div>
                      {/* <div
                      className=" col-3 d_comn_btn d-block"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      style={{ width: "40%", margin: "0px auto" }}
                    >
                      Login
                    </div> */}
                    </div>
                    <div>
                      <div id="login-cart" style={{ display: "none" }}>
                        <div className="text-center">
                          <label className="mb-4">
                            Phone no.
                            <input type="number" style={{ margin: "2%" }} />
                          </label>
                          {/* <button
                          className="d_comn_btn d-block"
                          style={{ margin: "0px auto", width: "30%" }}
                        >
                          Login
                        </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {selectedOption === "Delivery" && (
              <div className=" mt-2  bg-white shadow-zoom-effect" id="shawdowzooeffect">
                <p className="d_main_title poppins bglight box_padding" id='titlemr2'>
                  Delivery Address
                </p>
                
                <div
                  className="row cart-log-sign pb-2"
                  id="cart_address"
                  style={{ margin: "-20px 4% 1% 4%" }}
                >
                  <input
                    className="col"
                    type="text"
                    placeholder="Apartment no"
                    name="Appartment_no"
                    onChange={handleChange}
                    value={formValues.Appartment_no?formValues.Appartment_no:""}
                  />

                  
                  {/* {deliveryLocation ? (
                    <>
                    <select className="col" name="Land_mark" onChange={handleLocationChange} >
                      <option value={JSON.stringify({})}>Select Location</option>
                      {deliveryLocation.map((item, id) => (

                        <option key={id} value={JSON.stringify(item)}>{item.areaName}</option>
                      ))}
                    </select></>
                    ) : (<> */}
                    <input
                    type="text"
                    className="col"
                    placeholder="Locality"
                    name="Land_mark"
                    onChange={handleChange}
                    value={formValues.Land_mark?formValues.Land_mark:""}
                  />
                    {/* </>)
                  } */}
                
                  <input
                    type="number"
                    className="col"
                    placeholder="Pincode"
                    name="Pincode"
                    onChange={handleChange}
                    value={formValues.Pincode?formValues.Pincode:""}
                    // disabled={resturantDetails?.pincode?true:false}
                    // disabled={formValues.Land_mark ? true : resturantDetails?.pincode?true: false}
                  />
                </div>
                <div className="row cart-log-sign pb-2  ">
                  <label>
                    <input
                      className="col-10"
                      type="text"
                      placeholder="Address"
                      name="address"
                      style={{ marginTop: "-20px" }}
                      onChange={handleChange}
                      value={formValues.address?formValues.address:""}
                    />
                    <p style={{ color: "red", marginLeft: "6%" }}>
                      {formErrors.address}
                    </p>
                  </label>
                </div>
              </div>
              )}
              <div className=" mt-2  bg-white shadow-zoom-effect">
                <p className="d_main_title poppins bglight box_padding" id='titlemr3'>
                  {" "}
                  Offers {" "}<span className="offerspan" onClick={()=>{setOfferZone(true)}}> ( get amazing offer codes<LocalOfferIcon className="offersicon"/>)</span>
                </p>
                <div className="delivery-address container-fluid ">
                  {/* <label>Address</label> */}

                  <div
                    className="input-group "
                    style={{ width: "94.5%", marginLeft: "21px" }}
                  >
                    <input
                      type="text"
                      className="form-control line_height_control"
                      placeholder="Enter promo code"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="couponCode"
                      onChange={handleChange}
                      value={formValues?.couponCode}
                    />
                    {isCouponApplied?
                    <>
                      <div className="input-group-append" onClick={handleRemoveCoupon}>
                        <span className="input-group-text" id="basic-addon2">
                          Remove
                        </span>
                      </div>
                    </>
                    :
                    <>
                      <div className="input-group-append" onClick={handleCoupon}>
                        <span className="input-group-text" id="basic-addon2">
                          Redeem
                        </span>
                      </div>
                    </>}
                  </div>
                  {
                    !couponCodeTrue?
             
                  couponError && <p className="error_coupon">{couponError}</p> :""
                  }
                  {couponCodeTrue && (
                    <p className="valid_coupon">
                      Woohoo! You just got Rs. {couponCodeTrue.d_amount}/- discount.
                    </p>
                  )}
                </div>
        
              </div>
              <div className="container mt-2 p-4 bg-white shadow-lg rounded pickupOption">
  {/* Service Option Title */}
  <h4 className="mb-3 fw-bold ">Choose Service Option</h4>

  {/* Service Option Buttons */}
  <div className="d-flex justify-content-start mb-3">
    <button
      className={`btn ${selectedOption === "Pickup" ? "btn-primary-custom active-option" : "btn-outline-primary"} me-2`}
      onClick={() => handleOptionChange("Pickup")}
       disabled={isDisabled}
    >
      Pickup
    </button>
    <button
      className={`btn ${selectedOption === "Delivery" ? "btn-primary-custom active-option" : "btn-outline-primary"}`}
      onClick={() => handleOptionChange("Delivery")}
    >
      Delivery
    </button>
     </div>
     {isDisabled && (
  resturantDetails?.timingDays ? (
    <p style={{ color: "red" }}>
      Restaurant opening time is from {resturantDetails?.timingFrom} to {resturantDetails?.timingTo} for pickup.
    </p>
  ) : (
    <p style={{ color: "red" }}>
      No operating time available for the restaurant pickup.
    </p>
  )
)}


  {/* Confirmation Section */}
  {selectedOption === "Pickup" && (
    <div className="mt-3 pickup shadow-sm">
      <h5 className="fw-bold mb-3">Order Confirmation</h5>
      <p className="mb-2">
        <strong>Pickup Location:</strong> <br />
        {resturantDetails?.restaurantAddress}
      </p>
      <p className="mb-2">
  <strong>Select Pickup Time:</strong> 
  {/* {pickupSlot.map((slot, index) => (
    <span
      key={index}
      style={{ cursor: "pointer", textDecoration: pickup_time === slot ? "underline" : "none" }}
      onClick={() => setPickupTime(slot)}
    >
      {slot}{index < pickupSlot.length - 1 && ", "}
    </span>
  ))} */}
  <table>
 
  <tbody>
  {Array.isArray(pickupSlot) && pickupSlot.length > 0 ? (
    pickupSlot.map((slot, index) => {
      const now = new Date();
      const slotStart = new Date();
      const slotEnd = new Date();

      const [startHours, startMinutes] = slot.startTime.split(":").map(Number);
      const [endHours, endMinutes] = slot.endTime.split(":").map(Number);

      slotStart.setHours(startHours, startMinutes, 0, 0);
      slotEnd.setHours(endHours, endMinutes, 0, 0);

      const isCurrentTimeInSlot = now >= slotStart && now <= slotEnd;

      return (
        <tr key={index}>
          <td
            style={{
              cursor: "pointer",
              textDecoration: pickup_time === slot.startTime ? "underline" : "none",
            }}
            onClick={() =>
              setPickupTime(
                isCurrentTimeInSlot
                  ? now.toTimeString().split(" ")[0]
                  : slot.startTime
              )
            }
          >
            {slot.formattedSlot}
          </td>
        </tr>
      );
    })
  ) : (
    <tr>
      <td colSpan="1" style={{ textAlign: "center" }}>
        No pickup slots available.
      </td>
    </tr>
  )}
  {showPickupTimeError && (
    <tr>
      <td colSpan="1" style={{ color: "red", textAlign: "center" }}>
        Please select a pickup time.
      </td>
    </tr>
  )}
</tbody>



</table>

</p>

      <p>
        <strong>Estimated Time for Preparation:</strong> 15-20 mins
      </p>
    </div>
  )}
</div>

              <div className=" mt-2  bg-white  shadow-zoom-effect" style={{marginBottom:"40px"}}>
                <p className="d_main_title poppins bglight box_padding" id="titlemr4">      
                  Payment
                </p>
                <div className="col  " style={{ padding: "0 5%" }}>
                  <label className="d_custome_raido">
                    <span className="d_paid monster">Cash on Delivery</span>
                    <input
                      type="radio"
                      name="costType"
                      id="costType1"
                      value="1"
                      // {...(isCouponApplied?{checked:false}:"")}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="col pb-1 " style={{ padding: "0 5%" }}>
                  <label className="d_custome_raido">
                    <span className="d_paid monster">
                      Credit Card/Debit Card/Netbanking 
                    </span>
                    <input
                      type="radio"
                      name="costType"
                      id="costType2"
                      value="2"
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <p style={{ color: "red", margin: "3% 5%" }}>
                  {formErrors.payment_type}
                </p>
              </div>


          
            </div>
            <div className="col-5"  style={{paddingLeft:"5%"}}>
              <div className="bg-white mt-4" >
                <div >
                <h6 className=" poppins box_padding29" id="dfafgfsa">Bill Details</h6>
                <div className="d_order_borderbtm">
                {cartItems.map((item, index) => (
               <div className="cart-box" key={index}>
               <div className="product-image">
               <img
                  src={item.item_image_url? item.item_image_url:process.env.PUBLIC_URL + "/images/pro7.png"}
                  style={{ width: "100px" ,height:"60px"}}
                />
               </div>
               <div className="product-details">
                {/* <div className="d-flex"> */}
           
                  {/* </div> */}
                  <h3 className="product-name">{item.itemname}</h3>
                 {/* <label for="quantity">Quantity:</label> */}
                 <div className="d-flex" >

                 <span
                    className="wrapperr" 
                    style={{ minWidth: "120px",maxWidth:"120px", height: "30px", marginTop:"2%", marginBottom:"2%"}}
                  >
                    <span className="minus">
                      <RemoveIcon
                        onClick={() => handleChangeIncDec(item, -1)}
                      />
                    </span>
                    <span className="num">{item.qty}</span>
                    <span className="plus">
                      <AddIcon onClick={() => handleChangeIncDec(item, 1)} />
                    </span>
                  </span>
                  <p className="product-price" >₹{item.price}</p>
               
                  </div>
               </div>
             </div>
                  ))}
                  </div>
              
                </div>

                <div className="box_padding29 d_order_border_btm2">
                  <ul className="d_order_list_name mb-0 d-flex">
                    <li>
                      <img
                        src={process.env.PUBLIC_URL + "/images/icons/file.svg"}
                        alt=""
                      />
                    </li>
                    <li style={{ width: "89%" }}>
                      <p
                        className="monster p_gray"
                        style={{ backgroundColor: "#f9f9f9",marginBottom:"0" }}
                      >
                           </p>
                        <textarea
                        rows="1" cols="50"
                          type="text"
                          name="additional_info"
                          placeholder="Write any additional information..."
                          style={{
                            border:"none",
                            borderRadius: "5px",
                            width: "100%",
                            paddingLeft:"3%",
                            lineHeight:"20px"
                          }}
                          onChange={handleChange}
                        />{" "}
                   
                    </li>
                  </ul>
                </div>
                <div className="bill_details d_order_border_btm box_padding "style={{paddingTop:"0"}}>
                  {/* <h6 className=" poppins">Bill details</h6> */}
                  <div className="row gy-0">
                    <div className="col-8">Sub Total</div>
                    <div className="col">₹ {(subTotal- (deliveryCharge ?? cartItems[0]?.deliveryCharge))}</div>
                  </div>
                  <div className="row gy-0">
                    <div className="col-8">Delivery Charges</div>
                    {/* <div className="col">₹ {(deliveryCharge ?? cartItems[0]?.deliveryCharge)}</div> */}
                    <div className="col">
    ₹ {selectedOption === "Delivery" ? (deliveryCharge ?? cartItems[0]?.deliveryCharge) : 0}
  </div>
                  </div>
                  <div className="row gy-0">
                    <div className="col-8">Item Discount</div>
                    <div className="col">₹ {discountAmount || "0"}</div>
                  </div>
                  {/* <div className="row gy-0">
                  <div className="col-9">Taxes and charges</div>
                  <div className="col"> Free</div>
                </div> */}
                  <div className="row gy-0">
                    <div className="col-8">Total</div>
                    <div className="col"> ₹ {formValues.TotalAmount}</div>
                  </div>
                </div>
              </div>
          
              <div className=" mt-2  mb-4 " style={{display:"flex",justifyContent:"center"}}>
                <button className="d_comn_btn " onClick={handleSubmit}style={{padding:"2%"}}>
                  {isSubmitButtonLoading
                    ? "Loading..."
                    : " Proceed to Checkout"}
                </button>
              </div>
                   
             {/* End of promocode display block */}
            </div>
       
          </div>
        )}
                    </div>    
        <Footer />
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Your Order is Confirmed</Modal.Title>
        </Modal.Header>
        <Modal.Body>{result}</Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            className="add-on-more-btn"
            onClick={() => {
              handleClose();
              clearCart()
              window.location = "/your-order-history";
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={failedPayments} onHide={handleClosePopUpFailedPayments} centered>
        <Modal.Header closeButton>
          <Modal.Title>Payment Failed !</Modal.Title>
        </Modal.Header>
        <Modal.Body>Sorry, your payment has failed, Please try again later.</Modal.Body>
        <Modal.Footer>
          <button
            variant="secondary"
            className="add-on-more-btn"
            onClick={() => {
              handleClose();
              window.location = "/";
            }}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
{/* ------------------------------------------------- */}

<Modal show={offersZone} onHide={handleCloseOfferZone} centered >

        <Modal.Body  className="Mob_Otp_popup" style={{width:"400px"}} >   
        <img src={process.env.PUBLIC_URL+'/images/icons/cross.png'} alt="cross" onClick={handleCloseOfferZone} />
        <div style={{height:"300px",overflow:"scroll"}}>
          <p>Apply now to get amazing offers!</p>
             {promoCodeList.map((item,index)=>(
                      <div className="offer_coupons" key={index} >
                      <div className="row gy-0">
                        <div className="col text-success">{item.code}</div>
                      </div>
                      <div className="row gy-0">
                        <div className="col">{item.description}</div>
                      </div>
                      <div className="row gy-0">
                        <div class=" col-3 d_comn_btn d-block" id="cart_btn2" style={{ margin: "5px auto", cursor: "pointer",left:"29%", bottom:"0" }} onClick={()=>{handleApplyNow(item.code); handleCloseOfferZone()}}> Apply Now</div>
                      </div>
                    </div>        
                  ))}
                    </div>
                  </Modal.Body>
      </Modal>
      {!mobileVerified?<CheckMobileNumberOtp verifiedNumber={getverifiedMobileNumber}/>:""}
      
    </>
  );
};

export default Cart;
