import { apiBaseUrl } from "../config/constant";
import axios from "axios";
import { setSessionForModal } from "../utils/Helper";

export const placedAnOrder = async(formValues)=>{
    const response = await fetch(apiBaseUrl + "fnb/order", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formValues),
      });
      console.log("response",response);
      const data = await response.json();
      return data;
}

export const getOrderId = async () => {
  try {
    const response = await axios.get(apiBaseUrl + '/fnb/get_order_id');
    return response.data.response.result;
  } catch (error) {
    console.error(error);
  }
}
export function hasOtherItemsWithDifferentId(currentItemId,cartItems) {
  // Filter items with a different ID
  const otherItems = cartItems.filter(item => item.itemid !== currentItemId);

  // Check if the filtered array has any items
  return otherItems.length > 0;
}
export function belongsToSpecificCategories(currentItemCategoryId, categoryIdsToCheck) {
  // Check if the current item category ID is in the specified set
  return categoryIdsToCheck.includes(currentItemCategoryId);
}

// this function is for give latitude , longitude using pincode

export const findRestaurantPetpoojaIdByPincode = async (pincode) => {
  try {
    const response = await axios.get(apiBaseUrl + 'fnb/getRestaurantList');
    const restaurantListData = response?.data?.response?.result;

    for (const restaurant of restaurantListData) {
      for (const location of restaurant.deliveryLocation) {
        if (location.pincode === parseInt(pincode)) {
          // Assuming setSessionForModal is defined somewhere
          setSessionForModal('deliveryLocation', restaurant.deliveryLocation);

          const rtnData = {
            latitude: restaurant.latitude,
            longitude: restaurant.longitude,
          };

          return rtnData;
        }
      }
    }

    // If pincode is not found
    return null;
  } catch (error) {
    console.error('Error while fetching restaurant data:', error);
    return null; // Return null or handle the error as per your requirement
  }
};


// Function to retrieve addons for an item
export const getAddonsForItem = (addonData, addonIds) => {
  const addons = [];
  addonIds.forEach(addonId => {
      const addon = addonData.find(addon => addon.addonid === addonId);
      if (addon) {
          addons.push(addon);
      }
  });
  return addons;
};



export const disableBookEventButton = (eventsData) => {

  // Ensure eventsData is valid and has at least one element with valid date and time
  if (!eventsData || !eventsData[0]?.eventDate || !eventsData[0]?.startTime) {
    return true; // Disable the button if any required data is missing
  }

  // Create a Date object for the event date and time
  const eventDateTime = new Date(eventsData[0].eventDate);

  // Extract and set hours and minutes from startTime
  const [hours, minutes] = eventsData[0].startTime.split(":").map(Number);
  eventDateTime.setHours(hours, minutes, 0, 0);

  const currentTime = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = eventDateTime - currentTime;

  // Convert the time difference to hours
  const hoursRemaining = timeDifference / (1000 * 60 * 60);

  // Disable the button if the remaining time is less than 24 hours
  return hoursRemaining < 24;
};
const restaurantDetailsStr = sessionStorage.getItem('restaurantDetails');
console.log(restaurantDetailsStr);
export const pickupTime = (slotDurationInMinutes = 60) => {
  // Safely retrieve and parse restaurant details
  // const restaurantDetailsStr = sessionStorage.getItem('restaurantDetails');
  let restaurantDetails;

  try {
    restaurantDetails = restaurantDetailsStr ? JSON.parse(restaurantDetailsStr) : null;
  } catch (error) {
    console.error("Invalid JSON in sessionStorage for 'restaurantDetails':", error);
    restaurantDetails = null;
  }

  // Provide default timing if data is missing or invalid
  if (!restaurantDetails || !restaurantDetails.timingFrom || !restaurantDetails.timingTo) {
    restaurantDetails = { timingFrom: "10:00 AM", timingTo: "10:00 PM" };
  }

  const slots = [];

  // Convert 12-hour AM/PM format to 24-hour format
  const convertTo24HourFormat = (time) => {
    const [hourMinute, period] = time.split(" ");
    let [hour, minute] = hourMinute.split(":").map(Number);

    if (period === "PM" && hour !== 12) hour += 12;
    if (period === "AM" && hour === 12) hour = 0;

    return { hour, minute };
  };

  const { hour: startHour, minute: startMinute } = convertTo24HourFormat(restaurantDetails.timingFrom);
  const { hour: endHour, minute: endMinute } = convertTo24HourFormat(restaurantDetails.timingTo);

  let currentTime = new Date();
  currentTime.setHours(startHour, startMinute, 0, 0); // Set to the start time

  const endTime = new Date();
  endTime.setHours(endHour, endMinute, 0, 0); // Set to the end time

  // Get the current system time to compare with slots
  const now = new Date();

  while (currentTime < endTime) {
    const startTime = new Date(currentTime);
    const endSlotTime = new Date(currentTime);
    endSlotTime.setMinutes(currentTime.getMinutes() + slotDurationInMinutes);

    if (endSlotTime > endTime) break;

    // Skip the slot if it has already passed
    if (startTime <= now && endSlotTime <= now) {
      currentTime = endSlotTime;
      continue;
    }

    // Add metadata for each slot
    slots.push({
      formattedSlot: `${formatTime(startTime)} - ${formatTime(endSlotTime)}`,
      startTime: startTime.toTimeString().split(" ")[0], // H:m:s format
      endTime: endSlotTime.toTimeString().split(" ")[0], // H:m:s format
    });

    // Move to the next slot
    currentTime = endSlotTime;
  }

  console.log("slots", slots);
  return slots;
};


// Helper function to format time in HH:MM AM/PM format
const formatTime = (date) => {
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12; // Convert 24-hour to 12-hour
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${period}`;
};






 export  const validateRestaurantTimings = (restaurantDetails) => {
    const today = new Date();
    const currentDay = today.toLocaleDateString("en-US", { weekday: "short" }); // E.g., "Wed"
    const currentTime = today.getHours() * 60 + today.getMinutes(); // Convert current time to minutes

    // Destructure with null checks
    const { timingDays, timingFrom, timingTo } = restaurantDetails;

    // If any timing data is null, disable the button
    if (!timingDays || !timingFrom || !timingTo) {   
      return true;
    }

    // Convert from and to times to minutes
    const fromTimeInMinutes = convertTimeToMinutes(timingFrom);
    const toTimeInMinutes = convertTimeToMinutes(timingTo);

    // If the current day and time match the restaurant details, enable the button
    if (
      currentDay === timingDays &&
      currentTime >= fromTimeInMinutes &&
      currentTime <= toTimeInMinutes
    ) {
    return false
    } else {
    return true
    }
  };

  // Helper function to convert time like "10:00 AM" to total minutes
  const convertTimeToMinutes = (time) => {
    const [timePart, period] = time.split(" "); // Split time and AM/PM
    const [hours, minutes] = timePart.split(":").map(Number);
    let totalMinutes = hours * 60 + minutes;
    if (period === "PM" && hours !== 12) totalMinutes += 12 * 60;
    if (period === "AM" && hours === 12) totalMinutes -= 12 * 60;
    return totalMinutes;
  };
