import React, { useState,useEffect, useContext } from "react";
import { useParams, Link ,useNavigate} from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import { Dropdown } from 'react-bootstrap';
import {
  getUserCurrLoc,
  getUser,
  getToken,
  removeUserSession,
} from "../utils/UserAuthenticate";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import FnbContext from "../contextApi/FnbContext";


import {   getRequestOptions,
  apiBaseUrl,
  eventCategoryEventListApiUrl,
  fBDImageBaseUrl,
  perPageShowData,
  eventCategoryImageBaseUrl,
  eventReminderMeApiUrl,
  multipartRequestOptions,
  eventImageBaseUrl,
  EVENT,
  SEARCH_KEY,
  userFrom, } from "../config/constant";

const Header = (props) => {

  
  // serachbar
  const placeholders = [
    "Discover Workshops",
    "Order Fries",
    "Order Dips",
    "Order Apple Cider",
    "Buy Craft beer",
    "Buy Coasters",
    "Try Chicken Ghee Roast",

  ];
  const [counter, setCounter] = useState(0);
  const [placeholder, setPlaceholder] = useState(placeholders[counter]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((counter + 1) % placeholders.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [counter]);

  useEffect(() => {
    setPlaceholder(placeholders[counter]);
  }, [counter]);
  // end

  const user = getUser();
  const {  cartItems, subTotalfunction,handleChangeIncDec,deliveryCharge,subTotal,resturantMiniAmount } = useContext(FnbContext);
  const navigate = useNavigate();
  const [ispage, setIspage] = useState(1);
  const logout = () => {
    removeUserSession();
    window.location = "/";
  };

  const [showForCart, setShowForCart] = useState(false);
  const [error,setError]=useState('')
  const handleCloseForCart = () => setShowForCart(false);
  const handleShowForCart = () => {setShowForCart(true);subTotalfunction()};
  const [eventlist,setEventlist]=useState([])
  const { cateSlug } = useParams();
  const [emptyCartShow, setEmptyCartShow] = useState(false);
  const emptyCartImage = process.env.PUBLIC_URL + "images/ShopCart.png";

  const getData = async () => {
    try {
   
      let currentcateSlug = cateSlug || "";
      // here Api call for Category Page Data
      let result = await fetch(
        apiBaseUrl +
          eventCategoryEventListApiUrl +
          "?cateSlug=" +
          currentcateSlug +
          "&page=" +
          ispage +
          "&perPage=" +
          perPageShowData,
        getRequestOptions
      );
        result = await result.json();
          setEventlist([...eventlist, ...result.response.result.eventList]);

    } catch (error) {
      console.error(error);
    }
  };

  //define functions here

  ////////////////////////////////////////////////
  const [inputValue, setInputValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
  
    const filtered = eventlist.filter(item =>
      item.eventName.toLowerCase().includes(value.toLowerCase()) ||
      item.eventDescription.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredData(filtered);
  };
  const findMostMatchedItem = (items, query) => {
    const lowercaseQuery = query.toLowerCase();
    let mostMatchedItem = null;
    let highestMatchCount = 0;
  
    for (const item of items) {
      const matchCount = calculateMatchCount(
        item.eventName.toLowerCase(),
        item.eventDescription.toLowerCase(),
        lowercaseQuery
      );
  
      if (matchCount > highestMatchCount) {
        highestMatchCount = matchCount;
        mostMatchedItem = item;
      }
    }
  
    return mostMatchedItem;
  };
  
  const handleSearch = (event) => {
    event.preventDefault();
    
    if (filteredData.length > 0) {
      const mostMatchedItem = findMostMatchedItem(filteredData, inputValue);
      if (mostMatchedItem) {
        // Redirect to the relevant event page or perform an action
        // alert(`Redirecting to: ${mostMatchedItem.eventShareLink}`);
        navigate("/events");
      }
      }else if(SEARCH_KEY.EVENT.includes(inputValue.toLowerCase())){
        navigate("/events");
      }else if (SEARCH_KEY.FNB_BEER.includes(inputValue.toLowerCase())){
        navigate("/beer");
      }else if (SEARCH_KEY.FNB_FOOD.includes(inputValue.toLowerCase())){
        navigate("/food");
      }else if (SEARCH_KEY.FNB_MERCHANDISE.includes(inputValue.toLowerCase())){
        navigate("/merchandise");
      }else{
        navigate("/events");
      }
  };

  const calculateMatchCount = (name, description, query) => {
    const nameMatchCount = calculateStringMatchCount(name, query);
    const descriptionMatchCount = calculateStringMatchCount(description, query);
  
    return Math.max(nameMatchCount, descriptionMatchCount);
  };
  
  const calculateStringMatchCount = (str1, str2) => {
    let count = 0;
    const minLength = Math.min(str1.length, str2.length);
  
    for (let i = 0; i < minLength; i++) {
      if (str1[i] === str2[i]) {
        count++;
      } else {
        break;
      }
    }
  
    return count;
  };
  
 const formatUserName = (name, maxLength = 14) => {
    const capitalized = name.replace(/\b\w/g, char => char.toUpperCase());
    return capitalized.length > maxLength ? capitalized.substring(0, maxLength) + '...' : capitalized;
};

const handleLogout = () => {
  removeUserSession()
 navigate('/')
};
  return (
    <>
      {/* social icon section */}

      <div className="sticky-header">
      <header className="row h-main">
  <div className="col h-left" style={{ marginLeft: "2%" }}>
    <Link to="https://www.facebook.com/godoolally/" target="_blank">
      <img
        src={process.env.PUBLIC_URL + "/images/facebook.png"}
        alt="logo"
      />{" "}
    </Link>
    <Link to="https://www.facebook.com/godoolally/" target="_blank">
      <img
        src={process.env.PUBLIC_URL + "/images/icons/new icons/instagram.png"}
        alt="logo"
      />
    </Link>
    <Link to="https://mobile.twitter.com/godoolally/" target="_blank">
      <img
        src={process.env.PUBLIC_URL + "/images/icons/newTwittter.png"}
        alt="logo"
      />{" "}
    </Link>
  </div>

  {/* Doolally Logo */}
  <div className="col-6 h-center">
    <Link to="/">
      <img
        src={process.env.PUBLIC_URL + "/images/splashLogo.png"}
        alt="logo"
        style={{ width: "90px" }}
      />
    </Link>
  </div>

  {/* Right-side icons */}
  <div className="col-3 d-flex align-items-center justify-content-end" style={{ marginRight: "2%" }}>
    <Link to="https://wa.me/+919653188646" target="_blank" className="me-2">
      <img
        src={process.env.PUBLIC_URL + "/images/icons/new icons/phone-call.png"}
        alt="Phone Icon"
        style={{ width: "20px" }}
      />
    </Link>

    <div className="d-flex align-items-center " onClick={handleShowForCart} style={{position:"relative"}}>
      <img
        src={process.env.PUBLIC_URL + "/images/icons/new icons/shopping-cart.png"}
        alt="Cart Icon"
        style={{ width: "18px",margin:"11px"}}
      />
      {cartItems.length > 0 && (
        <span className="cart-qty-indicate">
          {cartItems.length}
        </span>
      )}
    </div>

    <Dropdown>
      <Dropdown.Toggle
        variant="text"
        id="dropdown-basic"
        style={{ fontSize: "15px", marginLeft: user ? "19%" : "5%" }}
      >
        {user ? (
          formatUserName(user.userName.length > 12 ? `${user.userName.slice(0, 10)}..` : user.userName)
        ) : (
          <img
            src={process.env.PUBLIC_URL + "/images/icons/Sample_User_Icon.png"}
            alt="User Icon"
            style={{ width: "20px" }}
          />
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu style={{ padding: "1px", textAlign: "center", marginTop: user ? "-7px" : "-14px", minWidth: "80px" }}>
        {user ? (
          <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
        ) : (
          <Dropdown.Item onClick={() => navigate("/login")}>Login</Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  </div>
</header>

   
        {/* <div className="row s-main">   
          <div className="col-2"></div>
          <div className="col-6" style={{ marginRight: "-8.4%", width: "49%" }}>
            <div class="search-container">
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  placeholder={placeholder}
                  name="search"
                      value={inputValue}
          onChange={handleInputChange}
                />
                <button type="submit">
                  <i class="fa fa-search"></i>
                </button>
              </form>
            </div>
          </div>
          <div
            className="col-2 header_navbar"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "start",
            }}
          >
          </div>
          <div className=" d-flex col-1">
          {cartItems.length > 0 ? (
            <>
              <div className="cart-container" onClick={()=>{handleShowForCart()
                 subTotalfunction()}}>
                <div class="cart-container">
                  <img
                    src={process.env.PUBLIC_URL +"/images/icons/new icons/shopping-cart.png"}
                    style={{ width: "20px", margin: "11px", marginLeft: "50px" }}
                    onClick={handleShowForCart}
                  />
                 
                  <span class="cart-qty-indicate">{cartItems
                    ? cartItems.length === 0
                      ? ""
                      : cartItems.length
                    : ""}</span>
                 
              
                </div>
              </div>
              </>
              ): (
                <>
                  <div className="cart-container" onClick={() => setEmptyCartShow(true) }>
                    <div class="cart-container">
                      <img
                        src={process.env.PUBLIC_URL +"/images/icons/new icons/shopping-cart.png"}
                        style={{ width: "20px", margin: "11px", marginLeft: "50px" }}
                      />
                      
                    </div>
                  </div>
                </>
              )}
            {/* )}            <Dropdown>
  <Dropdown.Toggle
    variant="text"
    id="dropdown-basic"
    style={{ fontSize: "15px" ,marginTop:"3px", marginLeft:user?"19%":"5%"}}
    // style={{ fontSize: "15px", margin: "3px 0 0 30%" }}
  >
   {user ? (
      formatUserName(user.userName.length > 12 ? `${user.userName.slice(0, 10)}..` : user.userName)
    ) : (
      <img
        src={process.env.PUBLIC_URL + "/images/icons/Sample_User_Icon.png"}
        style={{ width: "20px", margin: "11px 1px 11px 7px" }}
      />
    )}
  </Dropdown.Toggle>

  <Dropdown.Menu
    style={{
      padding: "1px",
      textAlign: "center",
      marginTop: user?"-7px":"-14px", // Reduces the gap between the icon and the dropdown
      minWidth: "80px",
      width: "auto",
     
    }}
  >
    {user ? (
      <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
    ) : (
      <Dropdown.Item onClick={() => navigate("/login")}>
        Login
      </Dropdown.Item>
    )}
  </Dropdown.Menu>
</Dropdown>

          </div>
        </div> */}
      </div>
      {cartItems ? (
        <Modal
          show={showForCart}
          onHide={handleCloseForCart}
          dialogClassName="modal-90w-cart"
        >
          <Modal.Header closeButton style={{ backgroundColor: "#a2c760" }}>
            <h4>Your ordered {cartItems.length>1?"items":"item"}</h4>
          </Modal.Header>

          <Modal.Body >
          <div style={{ maxHeight: "300px", overflowY: "auto" , paddingRight:"2%"}}>
            {cartItems.map((item, index) => (
              <div
                className="d-flex"
                style={{
                  border: "1px solid #d4d4d4",
                  borderRadius: "5px",
                  // boxShadow: "2px 5px 5px 5px #f5f5f5",
                  position:"relative",
                  marginBottom: "10px",
                  padding:"2%"
                }}
                key={index}
              >
                <img
                  src={item.item_image_url? item.item_image_url:process.env.PUBLIC_URL + "/images/pro7.png"}
                  style={{ width: "60px", marginRight:"6.6%" }}
                />

                <div>
                  <strong>
                    {item.itemname}
                  </strong>
                  <span
                    className="wrapperr mt-1 mx-1"
                    style={{ minWidth: "120px",maxWidth:"120px", height: "30px" }}
                  >
                    <span className="minus">
                      <RemoveIcon
                        onClick={() => {handleChangeIncDec(item, -1);if(cartItems.length === 1 && cartItems[0].qty===0)setShowForCart(false)}}
                      />
                    </span>
                    <span className="num">{item.qty}</span>
                    <span className="plus">
                    <AddIcon onClick={() => {setError('');handleChangeIncDec(item, 1)}} />
                    </span>
                  </span>
                </div>
                <div style={{ position:"absolute", right:"12px" , bottom:"12px"}}>
                  <strong>₹ {item.price}</strong>
                </div>
                {/* <DeleteOutlinedIcon onClick={()=>{removeItem(item)}} style={{marginTop:"23px"}}/> */}
              </div>
            ))}
            </div>
            {error&&<p className="errorMsg text-center">{error}</p>}
            
            <p
              className="text-center add_more_item"
              onClick={() => { 
                navigate("/beer")
                setShowForCart(false) 
              }}
            >
              Add more items
            </p>
            
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
          <div className="d-flex justify-content-between ">
            <div className="col-6 poppins hglight-title m-2 subTotalProce ">
              Sub total <strong> <span>₹ {(subTotal - (deliveryCharge ?? cartItems[0]?.deliveryCharge))}</span>
              </strong> <br />  
              <p> (Extra charges may apply) </p>
            </div>
            <div onClick={() => {
  if (resturantMiniAmount <= subTotal) {
    navigate('/cart');
  } else {
    setError(`Minimum order amount should be ${resturantMiniAmount}`)
  }
}}>  
            <button
              className="col-5"
              style={{
                marginTop:"8px",
                height: "35px",
                color:"white",
                backgroundColor: "#a2c760",
                outline: "none",
                border: "none",
                borderRadius: "5px",
                width:"100px",
                marginLeft:"-116px",
              }}
              // onClick={handleShowForm}
            >
             Checkout
             
            </button>
            </div>
          </div>
        </Modal>
      ) : (
        <></>
      )}

<>
        <Modal
        size="sm"
centered
        show={emptyCartShow}
        onHide={() => setEmptyCartShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
          Your cart is empty!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="menu-footer-mob" style={{ textAlign : "center"}} >
            <img src={emptyCartImage} />
            <h1>Cart Empty</h1>
            <Link className="btn btn-success" style={{background : "rgb(117, 146, 70)"}} to="/beer" onClick={() => setEmptyCartShow(false)}>View Menu</Link>
          </div>
        </Modal.Body>
      </Modal>
        </>

    </>
  );
};

export default Header;
