import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import FnbContext from "./FnbContext";
import Helper, {
  setSessionForAmount,
  setSessionForModal,
} from "../utils/Helper";
import { apiBaseUrl } from "../config/constant";
import { getSessionForModal } from "../utils/Helper";
import { useNavigate } from "react-router-dom";

const FnbState = (props) => {
  const data = getSessionForModal("longitude");
  const [restLocation, setRestLocation] = useState([]);
  const [deliveryCharge, setDeliveryCharge] = useState();
  const [beveragesItem, setBeveragesItem] = useState([]);
  const [beveragesCategory, setBeveragesCategory] = useState([]);
  const [foodCategory, setFoodCategory] = useState([]);
  const [foodItem, setFoodItem] = useState([]);
  const [merchandiseCategory, setMerchandiseCategory] = useState([]);
  const [merchandiseItem, setMerchandiseItem] = useState([]);
  const [cart, setCart] = useState([]);
  const [subTotal, setSubTotal] = useState('');
  const [addon, setAddon] = useState([]);
  const [reviews, setReviews] = useState([]);
  const initialCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
  const [cartItems, setCartItems] = useState(initialCartItems);
  const [areaCity, setAreatCity] = useState(data);
  const [location, setLocation] = useState();
  const [qty1, setQty] = useState([]);
  const [soldOutItems, setSoldOutItems] = useState([]);
  const [show, setShow] = useState(false);
  const [resturantDetails, setResturantDetails] = useState();
  console.log(resturantDetails,"resturant adetails")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [resturantMiniAmount, setResturantMinimumAmount] = useState("");
  const [itemsForReorder, setItemsForReorder] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // Define the API URL
    const apiUrl = apiBaseUrl + "fnb/order/getSoldoutList";

    axios
      .post(apiUrl)
      .then((response) => {
        setSoldOutItems(response.data.response.result);
      })
      .catch((error) => {
        // Handle errors
        console.error("API error:", error);
      });
  }, []);

  // get location APi

  // const fetchLocation = async () => {
  //   return new Promise((resolve, reject) => {
  //     if (navigator.geolocation) {
  //       navigator.geolocation.getCurrentPosition(
  //         (position) => {
  //           resolve(position.coords);
  //           setLocation(position.coords)

  //         },
  //         (error) => {
  //           reject(error);
  //         }
  //       );

  //     } else {
  //       reject(new Error('Geolocation is not supported by this browser.'));
  //     }
  //   });
  // }

  // end

  const onAdd = (item,selectedAddons) => {
    const categoryIdsToCheck = ['']; // Update this with actual category IDs
    let updatedCartItems = [...cartItems];
  
    // Calculate the total price of all selected addons
    const totalAddonPrice = selectedAddons.length > 0 ?selectedAddons.reduce(
      (total, addon) => total + parseFloat(addon.price ?? 0),
      0
    ):''
  
    const isCurrentItemBelongsToCategory = categoryIdsToCheck.includes(item.item_categoryid);
  
    if (cartItems.length) {
      if (isCurrentItemBelongsToCategory) {
        const hasItemsWithOtherCategory = cartItems.some(
          (series) => !categoryIdsToCheck.includes(series.item_categoryid)
        );
  
        if (!hasItemsWithOtherCategory) {
          const exist = cartItems.find((series) => series.itemid === item.itemid);
  
          if (exist) {
            // Update existing item quantity and addons
            updatedCartItems = cartItems.map((series) =>
              series.itemid === item.itemid
                ? {
                    ...exist,
                    qty: exist.qty + 1,
                    addon: selectedAddons, // Pass selectedAddons array
                    price: exist.unitPrice + totalAddonPrice,
                  }
                : series
            );
          } else {
            // Add new item with total addon price
            const temp = parseFloat(item.price) + totalAddonPrice;
            updatedCartItems = [
              ...cartItems,
              {
                ...item,
                qty: 1,
                addon: selectedAddons, // Pass selectedAddons array
                price: temp,
                unitPrice: temp,
                deliveryCharge: deliveryCharge,
              },
            ];
          }
        } else {
          updatedCartItems = [];
        }
      } else {
        const hasItemsWithOtherCategory = cartItems.some(
          (series) => !categoryIdsToCheck.includes(series.item_categoryid)
        );
  
        if (!hasItemsWithOtherCategory) {
          updatedCartItems = [];
        }
      }
    }
  
    const exist = updatedCartItems.find((series) => series.itemid === item.itemid);
    if (exist) {
      updatedCartItems = updatedCartItems.map((series) =>
        series.itemid === item.itemid
          ? {
              ...exist,
              qty: exist.qty + 1,
              addon: selectedAddons, // Pass selectedAddons array
              price: exist.unitPrice + totalAddonPrice,
            }
          : series
      );
    } else {
      const temp = parseFloat(item.price) + totalAddonPrice;
      updatedCartItems = [
        ...updatedCartItems,
        {
          ...item,
          qty: 1,
          addon: selectedAddons, // Pass selectedAddons array
          price: temp,
          unitPrice: temp,
          deliveryCharge: deliveryCharge,
        },
      ];
    }
  
    // Update cartItems state and quantity
    setCartItems(updatedCartItems);
    setQty(beveragesItem);
    // Optionally save to local storage
    // localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  };
  

  const handleChangeIncDec = (item, d) => {
    const index = cartItems.findIndex(
      (series) => series.itemid === item.itemid
    );
    if (index === -1) {
      return; // item not found in cart, do nothing
    }
    const arr = [...cartItems]; // create a new copy of the cartItems array
    arr[index].qty += d;
    if (arr[index].qty === 0) {
      arr.splice(index, 1); // remove the item from the array
    } else {
      arr[index].price = arr[index].qty * arr[index].unitPrice;
    }

    const pathname = window.location.pathname; // "/path/to/page"
    setCartItems(arr);
    if (!arr.length) {
      if (pathname === "/cart") {
        navigate("/");
      }
    }
    subTotalfunction();
  };

  const subTotalfunction = async () => {
    try {
      let totalAmount = await cartItems.reduce(async (itemPromise, index) => {
        let item = await itemPromise;
        let { price } = index;
        item = item + parseFloat(price);
        return item;
      }, Promise.resolve(0));
      const temp=deliveryCharge??cartItems[0].deliveryCharge
      setSubTotal(totalAmount + temp);
    } catch (error) {
      // Handle any errors
      console.error("Error calculating subtotal:", error);
    }
  };

  useEffect(() => {
    subTotalfunction();
  }, [cartItems, deliveryCharge]);
  const removeItem = (item) => {
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.itemid !== item.itemid
    );
    setCartItems(updatedCartItems);
    // Remove item from localStorage as well
    const cartItemsInLocalStorage = JSON.parse(
      localStorage.getItem("cartItems")
    );
    const updatedCartItemsLocalStorage = cartItemsInLocalStorage.filter(
      (cartItem) => cartItem.itemid !== item.itemid
    );
    // localStorage.setItem(
    //   'cartItems',
    //   JSON.stringify(updatedCartItemsLocalStorage)
    // )
    subTotalfunction();
    if (!cartItems || cartItems.length === 1 || cartItems.length === 0) {
      // alert('empty');
      navigate("/beer");
    }
    // console.log(cartItems);
  };

  const clearCart = () => {
    setCartItems([]);
    setCart([]);
    // localStorage.clear()
    subTotalfunction(); // update the subtotal if needed
  };

  const fnbBeerData = async (resid = "") => {
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({
    //     restID: restLocation,
    //   }),
    // };
    const requestData = {
      restID: resid ? resid : restLocation,
    };

    const headers = {
      "Content-Type": "application/json",
    };
    try {
      const response = await axios.post(
        apiBaseUrl + "common/get-restaurant-menus",
        requestData,
        { headers }
      );
      // console.log(response.data?.response?.result, "9080");
      const data = response.data?.response?.result;
      if (!data) {
        // Handle the case where the data is not available or has an unexpected structure
        console.log("Invalid data format");
        return;
      } else {
        setAddon(data?.addongroups);
        setItemsForReorder(data);
        const merchandiseCategory = data?.categories.find(category => category.categoryname === "Merchandise");
        const merchandiseCategoryId = merchandiseCategory ? merchandiseCategory.categoryid : null;
        setBeveragesItem(
          data.items?.filter(
            (item) =>
              // //For Test
              // item.item_categoryid === "69934" ||
              // item.item_categoryid === "69932" ||
              // item.item_categoryid === "69907" ||
              // item.item_categoryid === "69909" ||

              // Doolally Taproom (dadar) ---- 9nv7ebkz
              item.item_categoryid === "69932" || //Kombucha
              item.item_categoryid === "69939" || //Combo
              item.item_categoryid === "69937" ||//Doolally Beer & Ciders
              item.item_categoryid === "69907" || //Mocktail
              item.item_categoryid === "69909" || //soda
              item.item_categoryid === "69934"  || //Iced Tea 
              item.item_categoryid === "69910"  || //Milkshae
              // END

                // Doolally Taproom (dadar) ---- 5ij8cwa6
                item.item_categoryid === "2158813" ||//Doolally Beer & Ciders
                // END

                  // Doolally Taproom (pune central) ---- n9ycuwt2ldm
                  item.item_categoryid === "4374304" ||//Doolally Beer & Ciders
                  
                  // END
              // //Doolally Taproom (Andheri) ---- 9y6exv8n
              item.item_categoryid === "3694576" || //Kombucha
              item.item_categoryid === "17181918" || //Kombucha
              item.item_categoryid === "17181919" || //Kombucha
              item.item_categoryid === "2934065" || //Combo
              item.item_categoryid === "2934063" || //Doolally Beer & Ciders
              // // END

              // //Doolally Taproom (Thane) ---- 08nwcyqh
              item.item_categoryid === "3694578" || //Combo
              item.item_categoryid === "2934063" || //Doolally Beer & Ciders
            // // END

                  // //Doolally Taproom (Khar) ---- 27ovz15e
                  item.item_categoryid === "2911165" || //Kombucha
                  item.item_categoryid === "2911172" || //Combo
                  item.item_categoryid === "2911170" ||//Doolally Beer & Ciders
                  item.item_categoryid === "2911140" || //Mocktail
                  item.item_categoryid === "2911142" || //soda
                  item.item_categoryid === "2911167" ||  //Iced Tea 
                  item.item_categoryid === "4374304"   //Iced Tea 

                  
                // // END
                        // Doolally Taproom (Koregaon Park) ---- n9ycuwt2
              // item.item_categoryid === "4374285" || //Merchandise
              // item.item_categoryid === "4374312"  //Off Menu
              // item.item_categoryid === "69937" ||//Doolally Beer & Ciders
              // item.item_categoryid === "69907" || //Mocktail
              // item.item_categoryid === "69909" || //soda
              // item.item_categoryid === "69934"  || //Iced Tea 
              // item.item_categoryid === "69910"   //Milkshae
              // END
          )
        );
        setBeveragesCategory(
          data.categories?.filter(
            (item) =>
              item.categoryname === "Kombucha" ||
              item.categoryname === "Mocktail" ||
              item.categoryname === "Soda" ||
              item.categoryname === "Milkshake" ||
              item.categoryname === "Iced Tea" ||
              item.categoryname === "Doolally Beer & Ciders" 
          )
        );
        setFoodCategory(
          data.categories?.filter(
            (item) =>
              item.categoryname === "Breakfast" ||
              item.categoryname === "Starters" ||
              item.categoryname === "Salads" ||
              item.categoryname === "Pizza" ||
              item.categoryname === "Main Course" ||
              item.categoryname === "Desserts" ||
              item.categoryname==="Chinese Starter (Non Veg)" ||
              item.categoryname === "Chinese Starter (Veg)" ||
              item.categoryname === "Chinese Rice / Noodles (Veg)" ||
              item.categoryname === "Chinese Rice / Noodles (Non Veg)" ||
              item.categoryname === "Rice / Noodles With Gravy (Veg)" ||
              item.categoryname === "Rice / Noodles With Gravy (Non Veg)" ||
              item.categoryname === "Pav Bhaji" ||
              item.categoryname === "Pulav" ||
              item.categoryname === "Sandwich" 

          )
        );
        setFoodItem(
          data.items?.filter((item) =>
            // Test Categories
            [
              "69917", "69916", "69915", "69918", "69914", "69925", "69924",
              // Doolally Taproom (Dadar) ---- 5ij8cwa6
              "5561642", "5561647", "5564070", "5564071", "5564074", "5564075",
              "5623731", "5623732", "5623755",
              // Doolally Taproom (Andheri) ---- 9y6exv8n
              "2934040", "2934041", "2934042", "2934049", "2934043", "2934044",
              // Doolally Taproom (Thane) ---- 08nwcyqh
              "3694560", "3694561", "3694562", "3694567", "3694564", "3694578",
              // Other Categories
              "4374288", "4374290", "4374289", "4374291", "5105414", "4374295",
              "4374292", "2911147", "2911148", "2911149", "2911156", "2911150",
              "2911151", "3694578"
            ].includes(item.item_categoryid)
          )
        );
        
        
        setMerchandiseCategory(
          data.categories?.filter((item) => item.categoryname === "Merchandise")
        );
        setMerchandiseItem(
          data.items?.filter(
            (item) =>
              item.item_categoryid === merchandiseCategoryId
              // item.item_categoryid === "2934037" || // Doolally Taproom (Andheri) ---- 9y6exv8n
              // item.item_categoryid === "3694557" ||
              // item.item_categoryid === "69911" // Test
          )
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

//   const fetchMerchandiseData=async(resid='')=>{
//     try {
//       const requestData = {
//         restID: resid ? resid : restLocation,
//       };
  
//       const headers = {
//         "Content-Type": "application/json",
//       };
//       const response = await axios.post(
//         apiBaseUrl + 'common/get-restaurant-menus',
//         requestData,
//         { headers }
//       )
//       // console.log(response.data?.response?.result, "9080");
//       const data = response.data?.response?.result
// if(data){
//   setMerchandiseCategory(
//     data.categories?.filter((item) => item.categoryname === "Merchandise")
//   );
//   setMerchandiseItem(
//     data.items?.filter(
//       (item) =>
//         item.item_categoryid === "2934037" || // Doolally Taproom (Andheri) ---- 9y6exv8n
//         item.item_categoryid === "3694557" ||
//         item.item_categoryid === "69911" // Test
//     )
//   );
// }
 
//     }
//   catch(error){
//     console.error(error)
//   }
//   }

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        latitude: data.latitude,
        longitude: data.longitude,
      };
      try {
        const response = await axios.post(
          apiBaseUrl + "fnb/get-restaurant-data",
          requestData
        );
        const data = response.data.response.result;
        setResturantMinimumAmount(data.restaurantDetails.minimumOtrderAmount);
        setSessionForModal("dileveryLocation", data.diliveryLocation);
        setResturantDetails(data.restaurantDetails);
        setDeliveryCharge(data.restaurantDetails.deliveryCharge);
        setRestLocation(data?.restaurantDetails?.restaurantPetpoojaId);
        // console.log(data?.restaurantDetails?.restaurantPetpoojaId);
        let resid = data?.restaurantDetails?.restaurantPetpoojaId;
        fnbBeerData(resid);
      } catch (error) {
        console.log(error);
      }
    };

    if (data) {
      fetchData();
    }
    // let panIndiaMerchandise='9y6exv8nmm'
    // fetchMerchandiseData(panIndiaMerchandise)
  }, []);



  useEffect(() => {
    // Get cart items from localStorage
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems"));
    // console.log(storedCartItems,"000")
    // Set cart items to stored items, or an empty array if there are none
    setCartItems(storedCartItems || []);
    // Calculate subtotal and update state
    subTotalfunction();
    // Clean up function to save cart items to localStorage when component is unmounted
    // return () => {
    //   localStorage.setItem('cartItems', JSON.stringify(cartItems))
    // }
  }, []);
  useEffect(() => {
    // Save to local storage whenever cartItems change
    localStorage.setItem("cartItems", JSON.stringify(cartItems));
  }, [cartItems]);

  return (
    <>
      <FnbContext.Provider
        value={{
          beveragesItem,
          onAdd,
          cartItems,
          beveragesCategory,
          qty1,
          foodCategory,
          foodItem,
          merchandiseCategory,
          merchandiseItem,
          handleChangeIncDec,
          subTotalfunction,
          removeItem,
          subTotal,
          addon,
          clearCart,
          deliveryCharge,
          resturantDetails,
          soldOutItems,
          itemsForReorder,
          resturantMiniAmount,
        }}
      >
        {props.children}
      </FnbContext.Provider>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>{/* Add your footer content here */}</Modal.Footer>
      </Modal>
    </>
  );
};

export default FnbState;
